import type { CustomFieldValues, TournamentDto, PaginationResult } from "@masterblaster/api";
import { CompetitionStatus, getSwr, sendCommand } from "@masterblaster/api";
import type { SocialLink } from "@masterblaster/basics";
import { AccessLevels } from "@mb/auth";
import type {
    CompetitionMatchesUpdated,
    MatchDemoCreated,
    MatchScheduleModelRemoved,
    MatchScheduleModelUpdated,
    MatchSeriesUpdated,
} from "@messaging/core";
import { useTopicIdSubscription, useUserSubscription } from "@messaging/core";
import { useAuthorization } from "@services/AuthSerivce";
import type { ApplicationState } from "@store/core";
import { showConfirmDialog } from "@utils/DialogHelpers";
import { generateLink } from "@utils/generateLink";
import type { TFunction } from "i18next";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { convertToMatchSeries } from "./Converters";
import type { MatchDemo, MatchSeries, MatchSeriesTeam } from "./MatchSeries";
import { MatchSeriesFormat, MatchStatus } from "./MatchSeries";
import _ from "lodash";

export interface CompetitionMatches {
    competitionIds: string[];
    matches: MatchSeries[];
    page?: number;
    pageSize?: number;
    totalCount?: number;
}

export enum MatchResult {
    Win,
    Loss,
    Draw,
}

export interface MatchSeriesPointsDto {
    matchSeriesId: string;
    points: number;
    result: MatchResult;
    bye: boolean;
    updatedAt: string;
    matchInfo:
        | {
              format: MatchSeriesFormat;
              competitionId: string;
              parentLeagueId: string | undefined;
              roundIndex: number;
              completedAt: string | undefined;
          }
        | undefined;
    opponentTeam: ScoreboardTeamInfo | undefined;
    mapScores: { id: string; displayName: string; score: number | undefined; opponentScore: number | undefined }[];
}

export interface ScoreboardTeamInfo {
    id: string;
    name: string;
    avatarImageId: string;
}

export interface ScoreboardEntryDto {
    competitionId: string;
    gameId: string;
    teamId: string;
    points: number;
    wins: number;
    losses: number;
    draws: number;
    played: number;
    customFields: CustomFieldValues;

    team: ScoreboardTeamInfo | undefined;
    matches: MatchSeriesPointsDto[];
}

export interface CsPlayerStats {
    playerId: string;
    matchId: string;
    matchIndex: number;
    kills: number;
    deaths: number;
    assists: number;
    suicides: number;
}

export interface MatchBoxData {
    matchSeries: MatchSeries | undefined;
    tournament: TournamentDto;
    communityId: string;
    communityName: string;
    communitySocialLinks: SocialLink[] | undefined;
}

export enum MatchViewMode {
    Normal,
    Seeding,
    AdminSeeding,
}

/*
    Utils
*/

export const isMatchEmptyAndFinished = (matchSeries: MatchSeries) => {
    const isEmpty = matchSeries.teams.filter((x) => x.teamId).length === 0;
    const isFinished = matchSeries.status === MatchStatus.Finished;

    return isEmpty && isFinished;
};

/*
    HOOKS
*/

export const useMatchViewMode = (matchSeries: MatchSeries) => {
    const isGameAdmin = useAuthorization("Tournament", matchSeries.id, AccessLevels.GameAdmin);
    const viewMode = useMemo(() => {
        if (!matchSeries.competitionStatus) {
            return MatchViewMode.Seeding;
        }

        const hasStarted = matchSeries.competitionStatus >= CompetitionStatus.Started;
        if (isGameAdmin && !hasStarted) {
            return MatchViewMode.AdminSeeding;
        }

        if (!hasStarted) {
            return MatchViewMode.Seeding;
        }

        return MatchViewMode.Normal;
    }, [isGameAdmin, matchSeries.competitionStatus]);

    return { viewMode, isTeamsVisible: viewMode === MatchViewMode.AdminSeeding || viewMode === MatchViewMode.Normal };
};

export const onlySteamedMatches = (matches: MatchSeries[]) =>
    matches.filter((x) => {
        const { isTwitchStreamingEnabled } = x.settings;

        return isTwitchStreamingEnabled && x.status < MatchStatus.Finished;
    });

export const getHead2HeadTeams = (matchTeams: MatchSeriesTeam[]) =>
    _.chain(matchTeams)
        .filter((x) => Boolean(x.teamId))
        .orderBy((x) => x.slot)
        .value();

export const useHead2HeadTeams = (
    matchSeries: MatchSeries
): { teamA: MatchSeriesTeam | undefined; teamB: MatchSeriesTeam | undefined } => {
    const teams = useMemo(() => getHead2HeadTeams(matchSeries.teams), [matchSeries.teams]);

    const [teamA, teamB] = teams;

    return {
        teamA,
        teamB,
    };
};

export const useCompetitionMatches = (
    competitionId: string | undefined | null,
    options?: GetCompetitionMatchesOptions
) => {
    const { data, mutate, isLoading } = getCompetitionMatches(competitionId, options);

    // If the competition is a League, competitionIds are all the child tournaments
    const competitionIds = data?.competitionIds ?? [];

    useTopicIdSubscription<CompetitionMatchesUpdated>(
        competitionIds,
        "CompetitionMatchesUpdated",
        (evt) => {
            const matches = evt.matches
                .filter((x) => !options?.includeStreamedMatchesOnly || x.settings.isTwitchStreamingEnabled)
                .map((x) => convertToMatchSeries(x));

            const competitionIds = matches.map((x) => x.competitionId);
            mutate((prev) => ({ competitionIds: competitionIds, matches }), { revalidate: false });
        },
        [mutate]
    );

    useTopicIdSubscription<MatchSeriesUpdated>(
        competitionIds,
        "MatchSeriesUpdated",
        (evt) => {
            mutate(
                (prev) => {
                    if (options?.teamId) {
                        const hasTeam = evt.match.teams.find((x) => x.teamId === options.teamId) !== undefined;
                        if (!hasTeam) {
                            return prev;
                        }
                    }

                    const match = convertToMatchSeries(evt.match);

                    if (!prev) {
                        return { competitionIds: [match.competitionId], matches: [match] };
                    }

                    const idx = prev.matches.findIndex((x) => x.id === match.id);
                    if (idx !== -1) {
                        prev.matches[idx] = match;
                        return { ...prev, matches: [...prev.matches] };
                    } else {
                        const existing = prev.matches.filter((x) => x.id !== match.id) ?? [];
                        const updated = [...existing, match];
                        return { ...prev, matches: updated };
                    }
                },
                { revalidate: false }
            );
        },
        [mutate]
    );

    // We need to perform this filtering client side because we're getting raw push updates from the server.
    // const matches = data?.matches ?? [];
    const includeFinished = options?.includeFinished ?? true;
    const matches = useMemo(
        () =>
            (data?.matches ?? [])
                .filter((x) => !options?.includeStreamedMatchesOnly || x.settings.isTwitchStreamingEnabled)
                .filter((x) => includeFinished || x.status < MatchStatus.Finished),
        [data, options, includeFinished]
    );

    return {
        matches,
        isLoading,
        page: data?.page,
        pageSize: data?.pageSize,
        totalCount: data?.totalCount,
    };
};

export const useMatchSeries = (matchSeriesId: string) => {
    const {
        data: matchSeries,
        mutate,
        isLoading,
    } = getSwr<MatchSeries>(`api/matches/${matchSeriesId}`, {
        map: (x) => convertToMatchSeries(x),
    });

    useTopicIdSubscription<MatchSeriesUpdated>(
        matchSeriesId,
        "MatchSeriesUpdated",
        (evt) => {
            if (evt.match.id === matchSeriesId) {
                mutate(convertToMatchSeries(evt.match), { revalidate: false });
            }
        },
        [mutate]
    );

    return {
        matchSeries,
        isLoading,
    };
};

export const useMatchScheduleData = (includeFinished?: boolean) => {
    const user = useSelector((state: ApplicationState) => state.session.user);
    const { data: matches, mutate, isLoading } = getPlayerMatchSchedule(user?.id, includeFinished);

    useUserSubscription<MatchScheduleModelUpdated>(
        "MatchScheduleModelUpdated",
        (_) => {
            mutate();
        },
        [mutate]
    );

    useUserSubscription<MatchScheduleModelRemoved>(
        "MatchScheduleModelRemoved",
        (evt) => {
            mutate((prevMatches) =>
                prevMatches ? prevMatches.filter((x) => x.id !== evt.matchSeriesId) : prevMatches
            );
        },
        [mutate]
    );

    return {
        isLoading,
        matches: matches ?? [],
    };
};

/*

    Url
*/

export const generateMatchOverviewLink = (matchSeries: {
    id: string;
    format: MatchSeriesFormat;
    competitionId: string;
    parentLeagueId: string | undefined;
    roundIndex: number;
}) => {
    if (matchSeries.format === MatchSeriesFormat.Challenge) {
        return generateLink("CHALLENGE_ROUTES.CHALLENGE", { id: matchSeries.competitionId });
    }

    if (matchSeries.parentLeagueId) {
        // generateLink("LEAGUE_ROUTES.MATCHES", { leagueId: matchSeries.parentLeagueId })
        return generateLink("MATCH_ROUTES.LOBBY", { id: matchSeries.id });
    }

    return generateLink("LOBBY_TAB_ROUTES.MATCHES", {
        id: matchSeries.competitionId,
        roundIndex: matchSeries.roundIndex,
        matchId: matchSeries.id,
    });
};

/*
    GET
*/

export const getPlayerMatchSchedule = (playerId: string | undefined, includeFinished?: boolean) =>
    getSwr<MatchSeries[]>(playerId ? `api/match_schedule/player?includeFinished=${includeFinished ?? false}` : null, {
        map: (result) => result.map((x) => convertToMatchSeries(x)),
    });

export const getPlayerNextMatches = (playerId: string | undefined, includeFinished?: boolean) =>
    getSwr<MatchSeries[]>(playerId ? `api/match_schedule/next` : null, {
        map: (result) => result.map((x) => convertToMatchSeries(x)),
    });

export const getTeamMatches = (
    teamId: string | undefined,
    options?: {
        status?: MatchStatus;
        page: number;
        pageSize?: number;
    }
) =>
    getSwr<PaginationResult<MatchSeries>>(
        teamId
            ? `api/matches/team/${teamId}?status=${options?.status ?? ""}&page=${options?.page}&pageSize=${options?.pageSize}`
            : null
    );

export type GetCompetitionMatchesOptions = {
    includeStreamedMatchesOnly?: boolean;
    includeFinished?: boolean;
    teamId?: string;
    childTournamentId?: string;
    page?: number;
    pageSize?: number;
};
export const getCompetitionMatches = (
    competitionId: string | undefined | null,
    options?: GetCompetitionMatchesOptions
) =>
    getSwr<CompetitionMatches>(
        competitionId
            ? `api/matches/competition/${competitionId}?includeStreamedMatchesOnly=${
                  options?.includeStreamedMatchesOnly ?? false
              }&teamId=${options?.teamId ?? ""}&childTournamentId=${options?.childTournamentId ?? ""}&includeFinished=${
                  options?.includeFinished ?? true
              }&page=${options?.page ?? ""}&pageSize=${options?.pageSize ?? ""}`
            : null,
        {
            map: (result) => ({
                ...result,
                matches: result.matches.map((x) => convertToMatchSeries(x)),
            }),
        }
    );

export const useMatchDemos = (matchSeriesId: string) => {
    const { data, mutate } = getSwr<MatchDemo[]>(`api/matches/demos/match/${matchSeriesId}`);

    useTopicIdSubscription<MatchDemoCreated>(
        matchSeriesId,
        "MatchDemoCreated",
        (evt) => {
            mutate((prev) => (prev ? [...prev, evt.matchDemo] : [evt.matchDemo]), { revalidate: false });
        },
        [mutate]
    );

    return {
        matchDemos: data,
    };
};

/*
    Player commands
*/
export const readyUpTeamForMatch = (matchSeriesId: string, teamId: string) =>
    sendCommand("ReadyUpTeamCommand", { matchSeriesId, teamId });

/*
    Admin commands
*/

export const createOrUpdateMatchSeriesMatch = (matchSeriesId: string, matchIndex: number) =>
    sendCommand("CreateOrUpdateMatchSeriesMatchCommand", {
        matchSeriesId,
        index: matchIndex,
        displayName: "Map",
        name: "Map",
    });

export const addMatchSeries = (competitionId: string, round: number | undefined, mapIds: string[]) =>
    sendCommand("AddMatchSeriesCommand", {
        competitionId,
        round,
        mapIds,
    });

export const deleteMatchSeries = (matchSeriesId: string) => sendCommand("DeleteMatchSeriesCommand", { matchSeriesId });

export const updateMatchConnectionInfoFields = (matchSeriesId: string, fields: CustomFieldValues) =>
    sendCommand("UpdateMatchConnectionInfoCommand", {
        matchSeriesId,
        fields,
    });

export const startMatchSeries = (matchSeriesId: string) => sendCommand("StartMatchSeries", { matchSeriesId });

export const stopMatchSeries = async (matchSeriesId: string, t: TFunction<"common">) => {
    const confirmed = await showConfirmDialog(t("shared.stop_match"), t("shared.are_you_sure"));
    if (confirmed) {
        await sendCommand("StopMatchSeries", { matchSeriesId });
    }
};

export const resetMatchSeries = async (matchSeriesId: string, t: TFunction<("common" | "translation")[]>) => {
    const confirmed = await showConfirmDialog(
        t("common:shared.reset_match"),
        t("translation:components.tournament.match.drawer.match_controls.reset_match_desc")
    );
    if (confirmed) {
        await sendCommand("ResetMatchSeries", { matchSeriesId });
    }
};

export type MatchSeriesTwitchStreamingOptions = {
    matchSeriesId: string;
    isTwitchStreamingEnabled: boolean;
    twitchStreamingUrl?: string;
};
export const setMatchSeriesTwitchStreaming = (options: MatchSeriesTwitchStreamingOptions) =>
    sendCommand("UpdateMatchSeriesTwitchStreamingCommand", options);

export const completeMatchSeries = (matchSeriesId: string) => sendCommand("CompleteMatchSeries", { matchSeriesId });

export const stopMatchSeriesAutoForfeit = (matchSeriesId: string) =>
    sendCommand("StopMatchSeriesAutoForfeit", { matchSeriesId });

export const removeMatchSeriesTeam = (matchSeriesId: string, teamId: string) =>
    sendCommand("RemoveMatchSeriesTeam", { matchSeriesId, teamId });
